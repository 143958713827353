<template>
    <v-container fluid>
        <!--NOTE: GC Tsuika Form -->
        <v-form
            id="Insert"
            ref="Insert"
            enctype="multipart/form-data"
        >
            <!-- @submit.prevent="Insert" -->


            <template>
                <v-row>
                    <v-col cols="12" sm="12" md="12">
                        <v-card>
                            <v-card-title class="form-card-title">
                                <v-icon dark size="20" class="mr-2">mdi-card-bulleted-outline</v-icon>
                                <span class="span-style">{{ $t('homeHeader.constructionCode') }}</span>
                                <v-spacer></v-spacer>
                                <!-- <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-icon dark class="mr-2" v-bind="attrs" v-on="on">mdi-plus-box</v-icon>
                                    </template>
                                    <span>Add</span>
                                </v-tooltip> -->
                            </v-card-title>
                            <v-card-text>
                                <v-row class="mt-1">
                                    <v-col cols="4" sm="4" md="4">
                                        <v-text-field
                                            v-model="add.construction_code"
                                            v-mask="constructionMasked"
                                            @change="changeConstructionCode($event)"
                                            :label="$t('homeHeader.constructionCode')"
                                            dense
                                            outlined
                                            persistent-placeholder
                                            required
                                            class="form-field-margin required"
                                            autocomplete="off"
                                            :rules="rules.required"
                                        >
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="12" md="12">
                        <v-card>
                            <v-card-title class="form-card-title">
                                <v-icon size="20" dark class="mr-2">mdi-account-settings</v-icon>
                                <span class="span-style">{{ $t('form.userInfo') }}</span>
                            </v-card-title>
                            <v-card-text>
                                <v-row class="mt-1">
                                    <v-col cols="4" sm="4" md="4">
                                        <v-text-field
                                            v-model="add.email"
                                            :label="$t('global.email')"
                                            dense
                                            outlined
                                            persistent-placeholder
                                            required
                                            class="form-field-margin required"
                                            autocomplete="off"
                                            :rules="rules.required"
                                            readonly
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="4" sm="4" md="4">
                                        <v-text-field
                                            v-model="add.company_name"
                                            :label="$t('form.companyName')"
                                            dense
                                            outlined
                                            persistent-placeholder
                                            required
                                            class="form-field-margin required"
                                            autocomplete="off"
                                            :rules="rules.required"
                                            readonly
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="4" sm="4" md="4">
                                        <v-text-field
                                            v-model="add.user_name"
                                            :label="$t('global.name')"
                                            dense
                                            outlined
                                            persistent-placeholder
                                            required
                                            class="form-field-margin required"
                                            autocomplete="off"
                                            :rules="rules.required"
                                            readonly
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="4" sm="4" md="4">
                                        <v-text-field
                                            v-model="add.phone"
                                            :label="$t('form.phoneNumber')"
                                            dense
                                            outlined
                                            persistent-placeholder
                                            required
                                            class="form-field-margin required"
                                            autocomplete="off"
                                            :rules="rules.required"
                                            readonly
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="4" sm="4" md="4">
                                        <v-text-field
                                            v-model="add.fax_number"
                                            :label="$t('form.faxNumber')"
                                            dense
                                            outlined
                                            persistent-placeholder
                                            required
                                            class="form-field-margin required"
                                            autocomplete="off"
                                            :rules="rules.required"
                                            readonly
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="4" sm="4" md="4">
                                        <v-autocomplete
                                            v-model="add.desired_delivery_address"
                                            :label="$t('homeHeader.desiredDeliveryAddress')"
                                            :items="displayDesiredAddress"
                                            item-text="text"
                                            item-value="id"
                                            dense
                                            outlined
                                            persistent-placeholder
                                            required
                                            class="form-field-margin required"
                                            autocomplete="off"
                                            @change="loadSiteAddress(add.desired_delivery_address)"
                                            :rules="rules.required"
                                        >
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="4" sm="4" md="4">
                                        <template v-if="add.desired_delivery_address == 2">
                                            <v-text-field
                                                v-model="gcWarehouseAddress"
                                                :label="$t('homeHeader.deliveryAddress')"
                                                dense
                                                outlined
                                                persistent-placeholder
                                                required
                                                class="form-field-margin required"
                                                autocomplete="off"
                                                :rules="rules.required"
                                                >
                                            </v-text-field>
                                        </template>
                                        <template v-else>
                                            <v-text-field
                                                v-model="tempSiteDeliveryAddress"
                                                :label="$t('homeHeader.deliveryAddress')"
                                                dense
                                                outlined
                                                persistent-placeholder
                                                required
                                                :readonly="add.desired_delivery_address != 9"
                                                class="form-field-margin required"
                                                autocomplete="off"
                                                :rules="rules.required"
                                                >
                                            </v-text-field>
                                        </template>
                                    </v-col>
                                    <v-col cols="4" sm="4" md="4">
                                        <v-text-field
                                            v-model="add.recipient"
                                            :label="$t('homeHeader.recipient')"
                                            dense
                                            outlined
                                            persistent-placeholder
                                            required
                                            class="form-field-margin required"
                                            autocomplete="off"
                                            :rules="rules.required"
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="4" sm="4" md="4">
                                        <v-text-field
                                            v-model="add.recipient_tel_number"
                                            type="number"
                                            :label="$t('homeHeader.recipientTelNumber')"
                                            onKeyPress="if(this.value.length==12)return false;"
                                            dense
                                            outlined
                                            persistent-placeholder
                                            required
                                            class="form-field-margin required"
                                            autocomplete="off"
                                            :rules="rules.required"
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-textarea
                                            v-model="add.recipient_address"
                                            :label="$t('homeHeader.recipientAddress')"
                                            rows="2"
                                            class="form-field-margin required"
                                            dense
                                            outlined
                                            persistent-placeholder
                                            autocomplete="off"
                                            :rules="rules.required"
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="12" md="12">
                        <v-card>
                            <v-card-title class="form-card-title">
                                <v-icon dark size="20" class="mr-2">mdi-cart-variant</v-icon>
                                <span class="span-style">{{ $t('industryHeader.additionalOrderingIndustry') }}</span>
                                <v-spacer></v-spacer>
                            </v-card-title>
                            <v-card-text>
                                <v-row class="mt-1">
                                    <v-col cols="4" sm="4" md="4">
                                        <v-autocomplete
                                            v-model="add.additional_order_industry"
                                            :label="$t('industryHeader.additionalOrderingIndustry')"
                                            :items="displayAdditionalIndustry"
                                            :item-text="item => `( ${!item.additional_industry_id ? 'N/A' : item.additional_industry_id} )  ${item.product_industry_name}`"
                                            item-value="additional_industry_id"
                                            dense
                                            outlined
                                            persistent-placeholder
                                            required
                                            class="form-field-margin required"
                                            autocomplete="off"
                                            @change="selectedOrderIndustry(add.additional_order_industry)"
                                            :rules="rules.required"
                                        >
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="4" sm="4" md="4">
                                            <v-menu
                                                v-model="delivery_date_menu"
                                                ref="delivery_date_menu"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="auto"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    v-model="delivery_date_picker"
                                                    :label="$t('form.deliveryDate')"
                                                    name="delivery_date"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    outlined
                                                    color="#283a42"
                                                    readonly
                                                    dense
                                                    slot="activator"
                                                    style="font-size: 13px"
                                                    class="form-field-margin required"
                                                    persistent-placeholder
                                                    autocomplete="off"
                                                    :rules="rules.required"
                                                >
                                                    <v-icon slot="append" color="#0C2D48"> mdi-calendar </v-icon>
                                                </v-text-field>
                                                </template>
                                                <v-date-picker v-model="delivery_date_picker" :min="dateToday()" @input="delivery_date_menu = false" :allowed-dates="allowedDates" :events="eventColors" event-color="red" no-title></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="12" md="12">
                        <v-card>
                            <v-card-title class="form-card-title">
                                <v-icon size="20" dark class="mr-2">mdi-clipboard-list-outline</v-icon>
                                <span class="span-style">{{ $t('form.productsInfo') }}</span>
                                <v-spacer></v-spacer>
                                <v-icon size="25" dark class="mr-2" :disabled="disabledConditions()" @click="conditionFields++">mdi-plus-box-outline</v-icon>
                            </v-card-title>
                            <v-card-text style="padding: 0px;">
                                <template v-if="!conditionFields">
                                    <center><br><h3 style="color: #666666">{{ $t('global.conditionText') }}</h3><br></center>
                                </template>
                                <template v-for="index in conditionFields" v-else>
                                    <v-col cols="12" sm="12" md="12" :key="index + 'partsProducts'">
                                            <v-card>
                                                <v-card-title class="form-card-title" style="background: #3f5160 !important;">
                                                    <span class="span-style ml-4">Parts/Products {{index}}</span>
                                                    <v-spacer></v-spacer>
                                                    <!-- <v-icon class="mr-2" size="20" color="#cdc9c8" @click="deleteProductItem(index)">mdi-trash-can</v-icon> -->
                                                </v-card-title>
                                                <v-card-text style="border: 1px solid #274761;">
                                                    <v-row class="mt-1">
                                                        <template v-if="add.additional_order_industry == '分電盤'"> 
                                                            <v-col cols="4" sm="4" md="4">
                                                                <v-text-field
                                                                v-model="tempProductName[index - 1]"
                                                                :label="$t('homeHeader.partsProductsName')"
                                                                :items="partsProductArray"
                                                                :item-text="item => `( ${item.buzai_code} ) ${item.buzai_mei}`"
                                                                item-value="id"
                                                                dense
                                                                outlined
                                                                persistent-placeholder
                                                                required
                                                                class="form-field-margin required"
                                                                autocomplete="off"
                                                                :rules="rules.required"
                                                                >
                                                                </v-text-field>
                                                            </v-col>
                                                        </template>
                                                        <template v-else>
                                                            <v-text-field
                                                                v-show="!isHidden"
                                                                v-model="tempProductCode[index - 1]"
                                                                label="Product Code"
                                                                dense
                                                                class="form-field-margin required"
                                                                outlined
                                                                persistent-placeholder
                                                                autocomplete="off"
                                                                readonly
                                                            >
                                                            </v-text-field>
                                                            <v-text-field
                                                                v-show="!isHidden"
                                                                v-model="tempNewProductName[index - 1]"
                                                                label="Product Name"
                                                                dense
                                                                class="form-field-margin required"
                                                                outlined
                                                                persistent-placeholder
                                                                autocomplete="off"
                                                                readonly
                                                            >
                                                            </v-text-field>
                                                            <v-col cols="4" sm="4" md="4">
                                                                <v-autocomplete
                                                                v-model="tempProductName[index - 1]"
                                                                :label="$t('homeHeader.partsProductsName')"
                                                                @change="getSelectedUnit(tempProductName[index - 1], index)"
                                                                :items="partsProductArray"
                                                                :item-text="item => `( ${item.buzai_code} ) ${item.buzai_mei} ${!item.tekiyo ? '' : '- '+ item.tekiyo}`"
                                                                item-value="id"
                                                                dense
                                                                outlined
                                                                persistent-placeholder
                                                                required
                                                                class="form-field-margin required"
                                                                autocomplete="off"
                                                                :rules="rules.required"
                                                                >
                                                                </v-autocomplete>
                                                            </v-col>
                                                            <v-text-field
                                                                v-show="!isHidden"
                                                                v-model="tempUnitCode[index - 1]"
                                                                :label="$t('homeHeader.unit')"
                                                                dense
                                                                class="form-field-margin required"
                                                                outlined
                                                                persistent-placeholder
                                                                autocomplete="off"
                                                                readonly
                                                            >
                                                            </v-text-field>
                                                            <v-text-field
                                                                v-show="!isHidden"
                                                                v-model="tempSummary[index - 1]"
                                                                :label="$t('homeHeader.unit')"
                                                                dense
                                                                class="form-field-margin required"
                                                                outlined
                                                                persistent-placeholder
                                                                autocomplete="off"
                                                                readonly
                                                            >
                                                            </v-text-field>
                                                            <v-col cols="4" sm="4" md="4">
                                                                <v-text-field
                                                                    v-model="tempUnit[index - 1]"
                                                                    :label="$t('homeHeader.unit')"
                                                                    dense
                                                                    class="form-field-margin required"
                                                                    outlined
                                                                    persistent-placeholder
                                                                    autocomplete="off"
                                                                    readonly
                                                                >
                                                                </v-text-field>
                                                            </v-col>
                                                            <v-col cols="4  " sm="4   " md="4">
                                                                <v-text-field
                                                                    v-model="tempQuantity[index - 1]"
                                                                    :label="$t('homeHeader.quantity')"
                                                                    dense
                                                                    outlined
                                                                    persistent-placeholder
                                                                    required
                                                                    class="form-field-margin required"
                                                                    autocomplete="off"
                                                                    :rules="rules.required"
                                                                    @keypress="onlyNumber($event)"
                                                                    @paste.prevent
                                                                >
                                                                </v-text-field>
                                                            </v-col>
                                                        </template>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                </template>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="12" md="12">
                        <v-card>
                            <v-card-title class="form-card-title">
                                <v-icon size="20" dark class="mr-2">mdi-clipboard-edit-outline</v-icon>
                                <span class="span-style">{{ $t('form.causeReason') }}</span>
                            </v-card-title>
                            <v-card-text>
                                <v-row class="mt-1">
                                    <v-col cols="4" sm="4" md="4">
                                        <v-autocomplete
                                            v-model="add.additional_cause"
                                            :label="$t('homeHeader.additionalCause')"
                                            :items="causeData"
                                            dense
                                            outlined
                                            persistent-placeholder
                                            required
                                            class="form-field-margin required"
                                            autocomplete="off"
                                            :rules="rules.required"
                                        >
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="4" sm="4" md="4">
                                        <v-text-field
                                            dense
                                            v-model="add.additional_reason"
                                            :label="$t('homeHeader.additionalReason')"
                                            outlined
                                            persistent-placeholder
                                            required
                                            class="form-field-margin required"
                                            autocomplete="off"
                                            :rules="rules.required"
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="4" sm="4" md="4">
                                        <v-file-input
                                            small-chips
                                            v-model="file"
                                            :label="$t('homeHeader.situationPhoto')"
                                            dense
                                            multiple
                                            :prepend-icon="null"
                                            outlined
                                            accept="image/*"
                                            persistent-placeholder
                                            autocomplete="off"
                                            @change="onFileChange()"
                                            
                                            >
                                            <v-icon medium slot="append" color="#0C2D48">
                                            mdi-image-plus
                                            </v-icon>
                                        </v-file-input>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <!-- <v-divider></v-divider>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn type="submit" class="mr-3 mb-2 mt-2" color="#0C2D48" style="color: white; height: 28px !important;" dense>{{ $t('gcForm.submit') }}</v-btn>
                            </v-card-actions> -->
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-spacer></v-spacer>
                    <v-btn @click="Insert()" :disabled="isSubmitted" class="mr-3 mb-2 mt-2" color="#0C2D48" style="color: white; height: 28px !important;" dense>{{ $t('global.submit') }}</v-btn>
                    <!-- <v-btn @click="Insert()" disabled class="mr-3 mb-2 mt-2" color="#0C2D48" style="color: white; height: 28px !important;" dense>{{ $t('global.submit') }}</v-btn> -->
                </v-row>
            </template>
        </v-form>


        <snack-bar :snackbar="snackbar"></snack-bar>

        <v-overlay :value="loadMore" overlay>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
            <b v-if="sendingEmail"><p style="font-size: 15px !important; margin-left: -3px !important;">{{this.loadMoreMsg}}</p></b>
        </v-overlay>

        <!-- FOR new function Category 306 -->
        <v-overlay :value="loadMore1" overlay>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
            <b><p style="font-size: 15px !important; margin-left: -3px !important;">Please Wait...</p></b>
        </v-overlay>

    </v-container>
</template>
<script>
    import axios from 'axios'
    import moment from 'moment'
    import swal from 'sweetalert2'
    import SnackBar from '../includes/SnackBar.vue'
    import { mapActions, mapState } from 'vuex'


    export default {
        components: {
            "snack-bar": SnackBar
        },
        sockets: {
            updateGcTsuikaData: function(socket) {
                if(socket.system == 'gc-tsuika' && socket.dispatchType == 'response-local') {
                    // if(socket.request == 'successfullySaveToLocal') {
                    //     this.loadMore = false;
                    // }

                    if(socket.request == 'getTraderCode') {
                        this.traderCodeData = socket.data;
                        this.hasCheckTraderCode = true;
                    }

                    if(socket.request == 'loadSiteAddress') {
                        if(socket.data) {
                            if(this.add.desired_delivery_address == 1) {
                                this.tempSiteDeliveryAddress = socket.data
                                this.add.delivery_address = this.tempSiteDeliveryAddress
                            }
                            // this.add.delivery_address = this.tempDeliveryAddress
                            // this.tempDeliveryAddress = ''
                        }
                    }

                }
            },
            updateTsuikaUnitPrice: function(socket) {
                if(socket.system == 'gc-tsuika' && socket.dispatchType == 'response-local') {
                    if(socket.request == 'getUnitPrice') {
                        if(socket.data) {
                            if(!this.hasUnitPrice) {
                                this.hasUnitPrice = true;
                                this.add.tempUnitPrice = socket.data
                                this.accessSituationPhoto(this.hasUnitPrice, this.add.construction_code, this.file, this.add.situation_photo)
                            }
                        }
                    }
                }
            }
        },
        data: () => ({
            // snackbar
            loadMoreMsg: '',
            snackbar: {
                color: "blue-grey",
                text: "",
                show: false,
            },

            add: {}, // an object for storing data in form
            desiredDeliveryAddress: [ // an array for choosing of desired delivery address in form
                { id: 1, text: '現場'},
                { id: 2, text: 'GC仙台 ( A ) 倉庫', value: 'N/A'},
                { id: 3, text: 'GC宮城 ( B ) 倉庫', value: '宮城県仙台市宮城野区港4-4-1'},
                { id: 4, text: 'GC群馬 ( D ) 倉庫', value: '群馬県伊勢崎市西野町350'},
                { id: 5, text: 'GCタカノ ( F ) 倉庫', value: '富山県射水市流通センター水戸田3-6　城西物流倉庫㈱'},
                { id: 6, text: 'GC岡山 ( I ) 倉庫', value: '岡山県倉敷市東塚7丁目6-16'},
                { id: 7, text: 'GC広島 ( J ) 倉庫', value: '広島県神石郡神石高原町階見1239-1'},
                { id: 8, text: 'GC山陰 ( K ) 倉庫', value: '鳥取県境港市昭和町　無番地'},
                { id: 9, text: 'その他'},
            ],
            constructionMasked: 'XXXXXXX-XXXX', // to automatically mask the construction code in form
            tempDeliveryAddress: '', // this is a temporary v-model for desired delivery address
            tempSiteDeliveryAddress: '', //this is a temporary v-model for site delivery address
            // additionalIndustryData: [],
            partsProductArray: [], //an array for storing products per category code
            qtyNumbers: [1, 2, 3, 4, 5, 6, 7, 9 , 10, 11, 12, 13, 14, 15], //an array of fixed quantity numbers for products
            causeData: [ //an array for choosing additional cause
                { text: "GC" }, 
                { text: "HRD" }
            ],
            delivery_date_menu: false, //this v-model is for data-picker
            delivery_date_picker: '', //this v-model is for data-picker
            file: [], //an array for storing files (situation photo in form)
            gcDataArray: [],
            assigneeData: [], //an array that holds for assigneeData
            assignedEmail: '', //v-model for getting of assignedEmail
            conditionFields: 0, //this v-model is for adding conditions when clicking '+' icon in form
            tempProductCode: [], //this is for storing temporary product code in form
            tempNewProductName: [], //this is for storing temportay product name in form
            tempProductName: [], //for showing of data in form
            tempUnitCode: [], //this is for storing temp unit code in form
            tempUnit: [], //this is for storing unit (unit name based on temp code) in form
            tempSummary: [], //this is for storing temporary summary
            tempQuantity: [], //this is for storing temporary quantity
            hacchuForm: false,
            tempTraderCode: '',
            tempTraderCodeNo: '',
            tempConstCode: '',
            isHidden: true,
            isSubmitted: false, //to disabled submit button in form
            loadMore: false, //to indicate of loding of data
            loadMore1: false, //for Category 306 only
            sendingEmail: false,
            traderCodeData: {},
            hasCheckTraderCode: false, //to check if the consturction code has subcon
            hasUnitPrice: false, //to check if all selected products has unit price
            isSaveToHacchu: false, //to know if data is need to save in hacchu database
            tempUnitPrice: [], //for storing temporary unit price in form
            hasUploaded: false, //to know if the files that is inputted in situation photo is already uploaded to AWS S3
            gcWarehouseAddress: '', //v-model for gc warehouse addresses
            withDeliveryFee: ['368', '204', '208', '209', '101'], //'541', '537'
            withDeliveryFeeCondition: ['541', '537'],
            minimalDateRange: [],
        }),
        computed: {
            ...mapState(['accessRightData', 'rules', 'industryData', 'emailData', 'leadTimeData', 'leadFormDayData', 'tsuikaData',"translation"]),
            displayAdditionalIndustry() {
                let newData = this.industryData.map(r => { return {additional_industry_id: r.additional_industry_id, product_industry_name: r.product_industry_name, tekiyo: r.tekiyo } })

                return newData;
            },
            displayDesiredAddress() {
                let user = this.loggedInUser.sub_group + " ";
                let gcId = this.desiredDeliveryAddress.find(r => r?.text.includes(user))?.id;

                if(gcId) {
                    return this.desiredDeliveryAddress.filter(r => r.id == 1 || r.id == gcId || r.id == 9)
                } else {
                    return this.desiredDeliveryAddress.filter(r => r.id == 1 || r.id == 9);
                }
            },
            eventColors(){
                return this.minimalDateRange?.filter(data => {
                    const date = new Date(data), day = date.getDay(), sunday = 0;
                    return day != sunday;
                })
            },
        },
        methods: {
            ...mapActions(['getAccessRights', 'loadCategoryData', 'getTsuikaData', 'getEmail', 'getLeadTime', 'getLeadFormDay']),
            onlyNumber($event){
                // let keyCode = $event.keyCode ? $event.keyCode : $event.which;
                // if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                //     $event.preventDefault();
                // }
                let keyCode = $event.keyCode ? $event.keyCode : $event.which;
                let inputValue = $event.target.value;

                // Prevent input of '0' at the beginning of the number
                if (inputValue.length === 0 && keyCode === 48) {
                    $event.preventDefault();
                }

                // Allow input of numeric characters and the decimal point
                if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                    $event.preventDefault();
                }

            },
            dateToday(){
                var today = new Date();
                var dd = String(today.getDate()).padStart(2, '0');
                var mm = String(today.getMonth() + 1).padStart(2, '0');
                var yyyy = today.getFullYear();

                today = yyyy + '-' + mm + '-' + dd;
                return today;
            },
            async addLeadDays(startDate, leadDays) {
                let someDate = new Date(startDate);
                let numberOfDaysToAdd = leadDays - 1; // 1 is for starting of counting date of delivery date
                let result = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
                return new Date(result).toISOString().substr(0, 10);
            },
            async getMinimalDateRange(startDate, leadDays) {
                let someDate = new Date(startDate);
                let numberOfDaysToAdd = leadDays - 1; // 1 is for starting of counting date of delivery date
                let result = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
                return new Date(result).toISOString().substr(0, 10);
            },
            async newEndDate(endDate, cntSunday) {
                let newDate = new Date(endDate);
                let newDay = newDate.setDate(newDate.getDate() + cntSunday);
                return new Date(newDay).toISOString().substr(0, 10);
            },
            async filteredDateRange(){
                let leadDays = this.leadTimeData?.find(date =>  date.additional_industry_id == this.add.additional_order_industry);

                if(!leadDays) {
                    return [];
                }
                let getEndDate = await this.addLeadDays(this.dateToday(), leadDays?.lead_day), dateRange = [];

                if(getEndDate) {
                    let dateRange = await this.getDateRange(this.dateToday(), getEndDate);
                    let countSunday = dateRange.filter(data => {
                        const date = new Date(data), day = date.getDay(), sunday = 0;
                        if(day == sunday) {
                            return data;
                        }
                    })

                    if(!countSunday.length) {
                        dateRange = await this.getDateRange(this.dateToday(), getEndDate);
                    } else {
                        let newEndDate = await this.newEndDate(getEndDate, countSunday.length)
                        dateRange = await this.getDateRange(this.dateToday(), newEndDate);
                    }
                    return dateRange;
                } else {
                    return dateRange;
                }
            },
            allowedDates(val){
                let date = new Date(val), day = date.getDay(), saturday = 6, sunday = 0;
                return !this.minimalDateRange.includes(val) && day != saturday && day != sunday;
            },
            disabledConditions() {
                let bool = true;
                if(this.add.additional_order_industry && this.delivery_date_picker) {
                    if(!this.conditionFields) {
                        bool = false;
                    }else{
                        let len = this.conditionFields;
                        let prod = this.tempProductName;
                        let unit = this.tempUnit;
                        let qty = this.tempQuantity;

                        if(prod.length == len && unit.length == len && qty.length == len) {
                            if(prod.slice(-1).length && unit.slice(-1).length && qty.slice(-1).length) {
                                bool = false;
                            }
                        } 
                    }
                }
                return bool;
            },
            changeConstructionCode(event) {
                if(event.length == 12) {
                    const deliveryAddress = this.add.desired_delivery_address;
                    this.add.construction_code = event;

                    if(deliveryAddress == 1) {
                        this.$socket.emit('gcTsuikaDataIndustryData', {
                            system: 'gc-tsuika',
                            request: 'loadSiteAddress',
                            requestData: this.add.construction_code,
                            dispatchType: 'request-local',
                            port: this.windowLoc.port,
                            protocol: this.windowLoc.protocol,
                            hostname: this.windowLoc.hostname,
                        })
                    }
                }
            },
            async loadSiteAddress(val) {
                if(val == 1) {
                    this.$socket.emit('gcTsuikaData', {
                        system: 'gc-tsuika',
                        request: 'loadSiteAddress',
                        requestData: this.add.construction_code,
                        dispatchType: 'request-local',
                        port: this.windowLoc.port,
                        protocol: this.windowLoc.protocol,
                        hostname: this.windowLoc.hostname,
                    })
                }
                else if(val != 9 && val != 2) {
                    this.tempSiteDeliveryAddress = this.desiredDeliveryAddress.find(rec => rec.id == val).value;
                }
                else {
                    this.tempSiteDeliveryAddress = '';
                }
            },
            async selectedOrderIndustry(val) {
                if( val ) {
                    this.tempConstCode = this.add.construction_code

                    this.conditionFields = 0; //for clearning product conditions when changing category codes

                    /*  for clearing all arrays when changigng category codes */
                    this.tempProductCode.splice(0);
                    this.tempProductName.splice(0);
                    this.tempNewProductName.splice(0);
                    this.tempUnitCode.splice(0);
                    this.tempUnit.splice(0);
                    this.tempSummary.splice(0);
                    this.tempQuantity.splice(0);

                }

                if (val == '306'){
                    // console.log('eyyyy');
                    this.loadMore1 = true//for Category 306 only
                }

                let obj = { additional_industry_id: val, constCode: this.add.construction_code }

                this.$socket.emit('gcTsuikaData', {
                    system: 'gc-tsuika',
                    request: 'getTraderCode',
                    requestData: obj,
                    dispatchType: 'request-local',
                    port: this.windowLoc.port,
                    protocol: this.windowLoc.protocol,
                    hostname: this.windowLoc.hostname,
                });

                    Promise.all([   
                        await axios({
                            method: 'post',
                            url: `${window.api}/mysql/getSelectedCategory`,
                            data: obj,
                            headers: {
                                'x-api-key' : process.env.VUE_APP_HEADERS
                            }
                        }),
                        await axios({
                            method: 'post',
                            url: `${window.api}/mysql/getAssigneeData`,
                            data: obj,
                            headers: {
                                'x-api-key' : process.env.VUE_APP_HEADERS
                            }
                        })
                    ]).then(([categories, assignees]) => {
                        this.partsProductArray = categories.data;
                        // console.log(this.partsProductArray,'this.partsProductArray');
                        this.assigneeData = assignees.data.filter(res => !res.deleted_at);
                        this.loadMore1 = false//for Category 306 only
                    });
            },
            getSelectedUnit(selectedItem, index){
                // console.log(selectedItem,'selectedItem');
                // console.log(index,'index');
                this.tempProductCode[index - 1] = this.partsProductArray.find(id => id.id == selectedItem)?.buzai_code
                this.tempNewProductName[index - 1] = this.partsProductArray.find(id => id.id == selectedItem)?.buzai_mei
                this.tempUnitCode[index - 1] = this.partsProductArray.find(id => id.id == selectedItem)?.tani_cd
                this.tempUnit[index - 1] = this.partsProductArray.find(id => id.id == selectedItem)?.tani_mei
                this.tempSummary[index - 1] = this.partsProductArray.find(id => id.id == selectedItem)?.tekiyo
            },
            async getUnitPrice(tempCode, tempConstruction, tempOrderingIndustry) {
                this.$socket.emit('tsuikaUnitPrice', {
                    system: 'gc-tsuika',
                    request: 'getUnitPrice',
                    requestData: {
                        productCode: tempCode,
                        constructionCode: tempConstruction,
                        additional_order_industry: tempOrderingIndustry,
                    },
                    dispatchType: 'request-local',
                    port: this.windowLoc.port,
                    protocol: this.windowLoc.protocol,
                    hostname: this.windowLoc.hostname,
                })
            },
            checkHacchuRegistered() {
                const data = this.add;
                this.$socket.emit('gcTsuikaData', {
                    system: 'gc-tsuika',
                    request: 'hacchuRegistered',
                    requestData: {
                        construction_code: data.construction_code,
                        category_code: data.additional_order_industry 
                    },
                    dispatchType: 'request-local',
                    port: this.windowLoc.port,
                    protocol: this.windowLoc.protocol,
                    hostname: this.windowLoc.hostname,
                })
            },
            async Insert() {
                // console.log(this.traderCodeData,'Subcon');
                const noTraderCode = this.traderCodeData["TRADER_CD"];
                if (this.traderCodeData){
                    if (noTraderCode === " ") {
                        swal.fire({
                            width:450,
                            position: "center",
                            icon: "error",
                            title: `ご依頼の物件は初回の通常発注がされていません
                            日本産業支給品発注対象外の可能性があります。
                            発注開始時期を確認の上、対象外の場合は 
                            サプライヤーへ直接依頼をお願いいたします。<br>
                            The Construction_Code of ${this.add.construction_code} has no subcon.`,
                            showConfirmButton: true,
                            customClass: {
                                title: 'custom-swal-title',
                            },
                        });
                    }else{
                        let toUploadFile = this.file;
                        let notImageFile = toUploadFile.map(file => {
                            let applicableExtension = ["PNG", "JPG", "JPEG"];
                            let fileName = file.name.split(".");
                            let extension = fileName.slice(-1).toString().toUpperCase();

                            if(!applicableExtension.includes(extension)) {
                                return extension;
                            }
                        }).filter(r => r);
                        const findMainIncharge = this.emailData.find(rec => rec.additional_industry_id == this.add.additional_order_industry && rec.is_main_incharge == 1);
                        const getLeadDays = this.leadTimeData?.find(r => r.additional_industry_id == this.add.additional_order_industry);
                        const findDeliveryFee = this.industryData.find(r => r.additional_industry_id == this.add.additional_order_industry)?.delivery_fee;
                        // console.log(findDeliveryFee,'findDeliveryFee');
                        // console.log(findMainIncharge,'findMainIncharge');
                        if(!findMainIncharge) {
                            swal.fire({
                                width:395,
                                position: "center",
                                icon: "error",
                                title: `No in-charge person. Please add first.`,
                                showConfirmButton: false,
                                timer: 2500,
                            });
                        }else if(window._.isEmpty(this.traderCodeData)) {
                            swal.fire({
                                width:450,
                                position: "center",
                                icon: "error",
                                title: `ご依頼の物件は初回の通常発注がされていません
                                日本産業支給品発注対象外の可能性があります。
                                発注開始時期を確認の上、対象外の場合は 
                                サプライヤーへ直接依頼をお願いいたします。<br>
                                The Construction_Code of ${this.add.construction_code} has no subcon.`,
                                showConfirmButton: true,
                                customClass: {
                                    title: 'custom-swal-title',
                                },
                            });
                        }else if(notImageFile.length > 0) {
                            // console.log(this.translation);
                            if (this.translation == 'en'){
                                swal.fire({
                                    width:450,
                                    position: "center",
                                    icon: "error",
                                    title: `Please upload image only.`,
                                    showConfirmButton: false,
                                    timer: 2500,
                                });
                            }else{
                                swal.fire({
                                    width:490,
                                    position: "center",
                                    icon: "error",
                                    title: `画像データを添付して下さい。`,
                                    showConfirmButton: false,
                                    timer: 2500,
                                });
                            }
                        }else {
                            if(this.$refs.Insert.validate()) {
                                // console.log('INSERT DATA')
                                const findCode = this.emailData.find(incharge => incharge.additional_industry_id == this.add.additional_order_industry && incharge.is_main_incharge == 1)
                                this.add.latestOrderNo = this.tsuikaData.map(m => Math.max(m.order_number)).shift();
                                this.isSubmitted = true;
                                this.add.lead_days = getLeadDays?.lead_day;
                                // this.add.delivery_fee = findDeliveryFee;
                                this.add.receiver = findCode.user_id;

                                this.add.delivery_date = this.delivery_date_picker
                                this.add.tempProductCode = this.tempProductCode
                                // this.add.tempProductName = this.tempProductName
                                this.add.tempProductName = this.tempNewProductName,
                                this.add.tempUnitCode = this.tempUnitCode
                                this.add.tempUnit = this.tempUnit
                                this.add.tempSummary = this.tempSummary
                                this.add.tempQuantity = this.tempQuantity
                                this.add.tempTraderCode = !this.traderCodeData ? null : this.traderCodeData.TRADER_CD
                                this.add.tempTraderCodeNo = !this.traderCodeData ? null : this.traderCodeData.TRADER_KANRI_NO
                                this.add.assignedEmail = this.assignedEmail;
                                this.add.product_industry_name = this.industryData.find(res => res.additional_industry_id == this.add.additional_order_industry).product_industry_name;

                                this.add.hrd_incharge_email = this.emailData
                                .filter(r => r.additional_industry_id == this.add.additional_order_industry);
                                if (this.add.hrd_incharge_email.length == 2) {
                                    if (this.add.additional_order_industry == '327' || this.add.additional_order_industry == '746' || this.add.additional_order_industry == '747' || this.add.additional_order_industry == '748'){
                                        this.add.hrd_incharge_email = this.add.hrd_incharge_email
                                        .map(match => match.email)
                                        .shift(); // Get the email from the first match
                                    }else{
                                        this.add.hrd_incharge_email = this.add.hrd_incharge_email
                                        .slice(1) // Skip the first match
                                        .map(match => match.email)
                                        .shift(); // Get the email from the second match
                                    }
                                } else{
                                    this.add.hrd_incharge_email = this.add.hrd_incharge_email
                                        .map(match => match.email)
                                        .shift(); // Get the email from the first match
                                }

                                this.add.hrd_incharge_name = this.emailData.find(r=> r.additional_industry_id == this.add.additional_order_industry)?.user_name

                                this.add.email_to_supplier  = this.emailData.find(r=> r.additional_industry_id == this.add.additional_order_industry)?.email_to_supplier
                                this.add.email_cc_supplier  = this.emailData.find(r=> r.additional_industry_id == this.add.additional_order_industry)?.email_cc_supplier


                                if(!this.file.length) {
                                    this.add.situation_photo = null;
                                } 

                                if(this.add.desired_delivery_address == 2) {
                                    this.add.delivery_address = this.gcWarehouseAddress
                                } else{
                                    this.add.delivery_address = this.tempSiteDeliveryAddress
                                }

                                this.loadMore = true;
                                this.loadMoreMsg = 'SAVING DATA...';

                                const objData = this.add;

                                // const meterBoxFees = ['2060', '3001', '3002', '3003','3007'];//original deploy
                                //    TODO SSD23-1120876-553
                                const meterBoxFees = ['2060', '3001', '3002', '3003','3007','118','119','120','121','122','123'];//for additional fee
                                let withFee = 0, withoutFee = 0;
                                // console.log(this.tempProductCode,'tempProductCode');
                                objData.tempProductCode.forEach(el => {
                                    if(meterBoxFees.includes(el)) {
                                        withFee++;
                                    } else {
                                        withoutFee++;
                                    }
                                })

                                if(objData.additional_order_industry == '537') {
                                    if(withFee > 0 && !withoutFee) {
                                        this.add.delivery_fee = findDeliveryFee;
                                    } else {
                                        this.add.delivery_fee = 0;
                                    }
                                }
                                //    TODO SSD23-1120876-553
                                else if(objData.additional_order_industry == '208'){
                                    if(withFee > 0 && !withoutFee) {
                                        this.add.delivery_fee = 2000;
                                    } else {
                                        this.add.delivery_fee = findDeliveryFee;
                                    }
                                }
                                else {
                                    this.add.delivery_fee = findDeliveryFee;
                                }

                                //NEW FUNCTION:

                                if(objData.additional_order_industry == "204" || objData.additional_order_industry == "208" ||  objData.additional_order_industry == "209") {
                                    // const withPrice = true;
                                    this.getUnitPrice(objData.tempProductCode, objData.construction_code, objData.additional_order_industry)
                                    // .then(() => {
                                    //     this.accessSituationPhoto(withPrice, objData.construction_code, this.file, objData.situation_photo)
                                    // })
                                } else {
                                    const withPrice = false;
                                    this.accessSituationPhoto(withPrice, objData.construction_code, this.file, objData.situation_photo)
                                }

                            //END OF NEW FUNCTION
                            }
                        }

                    }
                }else{
                    swal.fire({
                        width:450,
                        position: "center",
                        icon: "error",
                        title: `ご依頼の物件は初回の通常発注がされていません
                        日本産業支給品発注対象外の可能性があります。
                        発注開始時期を確認の上、対象外の場合は 
                        サプライヤーへ直接依頼をお願いいたします。<br>
                        The Construction_Code of ${this.add.construction_code} has no subcon.`,
                        showConfirmButton: true,
                        customClass: {
                            title: 'custom-swal-title',
                        },
                    });
                }


            },
            accessSituationPhoto(withPrice, constructionCode, files, dateFileName){
                if(withPrice) {
                    if(!files.length && !dateFileName) {
                        this.hasUploaded = true;
                    } else {
                        this.uploadToAWS(constructionCode, files, dateFileName)
                        .then(val => {
                            if(val == 'success') {
                                this.hasUploaded = true;
                            }
                        })
                    }
                } else {
                    if(!files.length && !dateFileName) {
                        this.insertNewData().then((res) => {
                            if(res.status == 200) {
                                this.loadMoreMsg = 'SAVING DATA...';
                                this.sendEmailNotification().then(response => {
                                    if(response == 'success') {
                                    this.loadMore = false;
                                    swal.fire({
                                        icon: 'success',
                                        title: 'Email Sent',
                                        showConfirmButton: false,
                                        timer: 2000
                                }).then(() => {
                                    this.$router.push('/').catch(() => {});
                                })
                                }
                                }).catch(() => {
                                    this.$router.push('/').catch(() => {});
                                })
                            }
                        })
                    } else {
                        this.uploadToAWS(constructionCode, files, dateFileName)
                        .then(val => {
                            if(val == 'success') {
                                this.insertNewData().then((res) => {
                                    if (res.status==200){
                                        this.loadMoreMsg = 'SAVING DATA...';
                                        this.sendEmailNotification().then(response => {
                                            if(response == 'success') {
                                                this.loadMore = false;
                                                swal.fire({
                                                    icon: 'success',
                                                    title: 'Email Sent',
                                                    showConfirmButton: false,
                                                    timer: 2000
                                                }).then(() => {
                                                    this.$router.push('/').catch(() => {});
                                                })
                                            }
                                        }).catch(() => {
                                            this.$router.push('/').catch(() => {});
                                        })

                                    }


                                })
                            }
                        })
                    }
                }
            },
            async insertNewData(){
                // console.log('INSERT DATA 2')
                this.loadMoreMsg = 'SAVING DATA...';
                if(('tempUnitPrice' in this.add)) {
                    const url = `${window.api}/mysql/insertTsuika`;
                    const headers = { 'x-api-key' : process.env.VUE_APP_HEADERS };
                    let response = await axios.post(url, this.add, { headers })

                    if(response.status == 200) {
                        if(response.data == 'inserted') {
                            this.$store.commit('prevConstructionCode', this.add.construction_code)
                            /* NOTE: After saving data to RDS then save to Hacchu Database/System */
                            if(response.data == 'inserted' && this.add.additional_cause == 'GC') {
                                this.$socket.emit('gcTsuikaData', {
                                    system: 'gc-tsuika',
                                    request: 'insertGcTsuika',
                                    requestData: this.add,
                                    dispatchType: 'request-local',
                                    port: this.windowLoc.port,
                                    protocol: this.windowLoc.protocol,
                                    hostname: this.windowLoc.hostname,
                                })
                            }
                        }
                        return response;
                    } else {
                        alert("Error. Please retry again later.");
                    }
                }
            },

            async sendEmailToGcPlanner(){
                
                let emailContent = Object.assign({}, this.emailContent()), data = this.add;
                let senderEmail = [data.email];
                // let categoryAssignEmail = this.$store.state.categoryEmails.find(r => r.id == data.additional_order_industry)?.emailAddress; //original
                let categoryAssignEmail = this.emailData.filter(r => r.additional_industry_id == data.additional_order_industry && r.is_email_reciever =='1').map(r => r.email);
                // console.log(categoryAssignEmail,'categoryAssignEmail');

                let sendTo = senderEmail; //original
                let ccData = ['iconn@hrd-s.com', 'le-garcia@hrd-s.com', 'a-maeda@hrd-s.com', 'm-nakashima@hrd-s.com'] //original
                let toCc = ccData.concat(categoryAssignEmail);

                //REVIEW Change when Deploy
                // const url = 'http://127.0.0.1:8000/api/mail/gctsuika/store' //LOCAL/DEVELOPER
                const url = 'https://ihs.hrd-s.com/hrdinquiry_email/api/mail/gctsuika/store' //ACTUAL-TESTBUCKET


                emailContent['to'] = sendTo;
                emailContent['senderStatus'] = 'Gc Planner';
                emailContent['cc'] = toCc;
                emailContent['status'] = data.additional_cause == 'GC' ? '全ての注文は受領されました。[GC起因]' : '全ての注文は起因調査中です。[HRD起因]';

                return await axios({
                    method: 'POST',
                    url: url,
                    data: emailContent
                }).then(res => {
                    if(res.data == 'Done!') {
                        return 'success';
                    }
                })
                
            },

            //REVIEW Change when Deploy
            emailContent(){
                let data = this.add;
                let categoryData = this.industryData.find(r => r.additional_industry_id == data.additional_order_industry);
                let categoryName = categoryData.additional_industry_id + " " + categoryData.product_industry_name;

                return {
                    construction_code: data.construction_code,
                    // from: '[TEST]: Gc Tsuika Hacchu System', //DEVELOPER - Testbucket
                    from: 'Gc Tsuika Hacchu System', //ACTUAL
                    senderEmail: 'no-reply@hrd-ihs.com',
                    // subject: '[TEST]: Gc Tsuika Hacchu System', //DEVELOPER - Testbucket
                    subject: 'Gc Tsuika Hacchu System', //ACTUAL
                    attachment: [],
                    remarks: "",
                    name: this.loggedInUser.user_name,
                    categoryName: categoryName,
                    senderUser: data.email,
                }
            },
            async gcReason(){
                let gcEmailResponse = await this.sendEmailToGcPlanner();

                if(gcEmailResponse === 'success') {
                    return 'success';
                } else {
                    return 'failed';
                }
            },
            async hrdReason(){
                let gcEmailResponse = await this.sendEmailToGcPlanner();

                if(gcEmailResponse == 'success') {
                    return 'success';
                } else {
                    return 'failed';
                }
            },
            /* This function is to send email to HRD Users */
            async sendEmailNotification() {
                this.loadMore = true;
                this.sendingEmail = true;
                if(this.add.additional_cause == 'GC') {
                    return await this.gcReason();
                } else {
                    return await this.hrdReason();
                }
                
            },
            onFileChange(){
                let today= moment().format('YYYY-MM-DD')
                this.add.situation_photo = JSON.stringify(this.file.map(res=>`${today}/${res.name}`))
            },
            async uploadFile(){
                let formdata = new FormData();
                let dateFormat = this.add.situation_photo.split(" ");
                formdata.append("dateFormat", `${dateFormat[0]} ${dateFormat[1]}`)
                formdata.append("construction_code", this.add.construction_code);

                // formdata.append("file", this.file);

                for(let file of this.file) {
                    formdata.append("files[]", file);
                }
                
                await axios.post(`${window.api}/uploadMultipleFiles`, formdata).then(res => {
                    if(res.data) {
                        // console.log(res.data)
                    }
                }).catch(err => {
                    console.log(err.message());
                })
            },
            changeUser(){
                const assignId = this.add.assigned_to
                const getEmail = this.assigneeData.find(res => res.user_id == assignId);
                
                this.assignedEmail = getEmail.email;
            },
            async getDateRange(start, end) {
                const range = [];
                const currentDate = new Date(start), endDate = new Date(end);

                while (currentDate <= endDate) {
                    range.push(currentDate.toISOString().substr(0, 10));
                    currentDate.setDate(currentDate.getDate() + 1);
                }
                return range;
            }
        },
        watch: {
            add: {
                handler(data) {
                    if( this.tempConstCode ) {
                        if( data.construction_code != this.tempConstCode ) return this.add.additional_order_industry = ''
                        
                        if(data.additional_order_industry != "204" || data.additional_order_industry != "208" ||  data.additional_order_industry != "209") {
                            this.add.tempUnitPrice = [];
                        }

                    }

                    if(data.construction_code && data.additional_order_industry) {
                        this.filteredDateRange().then(res => {
                            this.minimalDateRange = res;
                        })
                    }
                },
                deep: true,
            },
            hasUploaded(val) {
                if(val) {
                    if(this.hasUnitPrice) {
                        if('tempUnitPrice' in this.add) {
                            this.insertNewData().then((res) => {
                                if (res.status == 200){
                                    this.loadMoreMsg = 'SAVING DATA...';
                                    this.sendEmailNotification().then(response => { //comment response
                                        if(response == 'success') {
                                            this.loadMore = false;
                                            swal.fire({
                                                icon: 'success',
                                                title: 'Email Sent',
                                                showConfirmButton: false,
                                                timer: 2000
                                            }).then(() => {
                                                this.$router.push('/').catch(() => {});
                                            })
                                        }
                                }).catch(() => {
                                    this.$router.push('/').catch(() => {});
                                })

                                }

                            });
                        }
                    }
                }
            },
        },
        mounted() {},
        created() {
            this.$socket.emit('gcTsuikaData', {
            system: 'gc-tsuika',
            request: 'TestRequest',
            port: this.windowLoc.port,
            protocol: this.windowLoc.protocol,
            hostname: this.windowLoc.hostname,
        })

            if(this.$store.state.prevConstructionCode) {
                this.add.construction_code = this.$store.state.prevConstructionCode;
            }

            if(Object.keys(this.loggedInUser).length != 0) {
                this.add['account_type'] = this.loggedInUser.account_type;
                this.add['company_name'] = this.loggedInUser.sub_group;
                this.add['email'] = this.loggedInUser.email;
                this.add['fax_number'] = this.loggedInUser.fax_number;
                this.add['phone'] = this.loggedInUser.phone;
                this.add['user_id'] = this.loggedInUser.user_id;
                this.add['user_name'] = this.loggedInUser.user_name;
            }

            this.loadCategoryData().then(() => {
                this.getEmail().then(() => {
                    this.getLeadTime().then(() => {
                        this.getLeadFormDay().then(() => {
                            this.getTsuikaData();
                        });
                    });
                });
            })
        },

        

    }

</script>

<style>
.custom-swal-title {
    font-size: 16px ;
}
</style>